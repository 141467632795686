import React from "react"
import { formatCurrency } from "@ncs/ts-utils"
import { Box } from "../layout"
import { Paragraph } from "./Paragraph"
import { css } from "@emotion/react"

export interface PartPriceEcommProps {
	price: number | string | null
	netPrice: number | string | null
	isParent?: boolean | null
}

export const PartPriceEcomm: React.FC<PartPriceEcommProps> = ({ price, netPrice, isParent }) => {
	const discountPercentage = (netPrice: number, price: number) => {
		const discount = Math.round(((price - netPrice) / price) * 100)

		if (netPrice < price && discount > 0) {
			return discount
		} else {
			return 0
		}
	}

	const parsedPrice = parseFloat(price?.toString() || "0")
	const parsedNetPrice = parseFloat(netPrice?.toString() || "0")

	let discount = 0
	if (parsedPrice && parsedNetPrice) {
		discount = discountPercentage(parsedNetPrice, parsedPrice)
	}

	return (
		<Box>
			{!isParent && (parsedPrice === parsedNetPrice || parsedNetPrice > parsedPrice) ?
				<div css={priceBox}>
					<Paragraph fontSize={1.25} customColor="#111827" bold>
						{formatCurrency(parsedPrice ?? 0)}
					</Paragraph>
				</div>
			:	<div css={priceBox}>
					<Paragraph fontSize={1.25} customColor={isParent ? "black" : "#10A85F"} bold>
						{formatCurrency(parsedNetPrice ?? 0)}
						{isParent ? " +" : null}
					</Paragraph>
					<Paragraph
						fontSize={0.8}
						customColor={discount > 0 ? "#374151" : "transparent"}
					>
						Your Price (${discount}% Off)
					</Paragraph>
				</div>
			}
		</Box>
	)
}

const priceBox = css`
	height: 3rem;
	display: flex;
	flex-direction: column;
`
