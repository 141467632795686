import React, { memo } from "react"
import { css } from "@emotion/react"
import { Link, useHistory } from "react-router-dom"
import { PartPriceEcomm, trackEvent, TrackingEvent } from "@ncs/web-legos"
import { isNonParentCustomerPart, NonParentCustomerPart, useCustomerPart } from "@ncs/ncs-api"
import { ButtonEcomm, CardProps, Paragraph, PartImage, EventTracker } from "@ncs/web-legos"

import { useShopContext } from "~/contexts"

interface PartCardProps extends CardProps {
	partId: string
	onAddToCart?: (partId: string) => void
}

export const PartPreviewCardEcomm: React.FC<PartCardProps> = memo(
	({ partId, onAddToCart, ...rest }) => {
		const [part, loading] = useCustomerPart(partId)
		const history = useHistory()
		const [, shopDispatch] = useShopContext()

		const handleAddToCart = (partToAdd: NonParentCustomerPart) => {
			shopDispatch({
				type: "add part to cart",
				payload: { part: partToAdd },
			})

			// Fire the passed in handler to open to the added to cart modal.
			if (onAddToCart) onAddToCart(partToAdd.id)
			trackEvent(TrackingEvent.ADD_TO_CART, {
				id: partToAdd.id,
				name: partToAdd.description,
			})
		}

		const goToProduct = (id: string) => {
			history.push(`/shop/parts/${id}`)
			trackEvent(TrackingEvent.GO_TO_PRODUCT, { id, name: part?.description })
		}

		if (loading || !part) {
			return null
		}

		return (
			<div css={partCard}>
				<>
					<EventTracker
						event={TrackingEvent.CARD}
						data={{ id: part.id, name: part.description }}
					>
						<Link to={`/shop/parts/${part.id}`}>
							<PartImage src={part.imageUrl} css={partImageStyle} alt={part.title} />
						</Link>
					</EventTracker>

					<EventTracker
						event={TrackingEvent.CARD}
						data={{ id: part.id, name: part.description }}
					>
						<Paragraph
							mt={1.5}
							mb={0.5}
							color="secondary"
							fontSize={0.8}
							customColor="#003264"
							bold
							textTransform="uppercase"
						>
							{part?.brandName}
						</Paragraph>
						<Link to={`/shop/parts/${part.id}`} css={headingLinkStyle}>
							<Paragraph css={productTitle}>{part.title}</Paragraph>
						</Link>
					</EventTracker>

					<>
						<Paragraph mb={1.5} mt={0.4} color="secondary" fontSize={0.8}>
							{isNonParentCustomerPart(part) ?
								`#${part.onlinePartNumber}`
							:	`${part?.variantCount} Options Available`}
						</Paragraph>
						<PartPriceEcomm netPrice={part.netPrice ?? 0} price={part?.price ?? 0} />
					</>
				</>
				{isNonParentCustomerPart(part) ?
					<ButtonEcomm
						variant="primary-cta"
						onClick={() => handleAddToCart(part)}
						fillContainer
						containerProps={{
							mt: 2,
						}}
					>
						Add To Cart
					</ButtonEcomm>
				:	<ButtonEcomm
						variant="secondary-cta"
						onClick={() => goToProduct(part.id)}
						fillContainer
						containerProps={{
							mt: 2,
						}}
					>
						See Options
					</ButtonEcomm>
				}
			</div>
		)
	}
)

const partCard = css`
	display: flex;
	max-height: 495px;
	height: 495px;
	max-width: 217px;
	width: 220px;
	min-width: 220px;
	min-height: 495px;
	flex-direction: column;
	justify-content: space-between;
	transition: box-shadow 0.3s ease-in-out;
	padding-right: 1rem;

	a:hover,
	a:focus {
		text-decoration: none;
	}

	&:hover {
		cursor: pointer;
	}

	&:hover button.css-dvbig9-baseButtonStyle-buttonWidthStyle-variantStyle {
		background-color: #00254a;
	}

	&:hover button.css-upcgpj-baseButtonStyle-buttonWidthStyle-variantStyle {
		border: 2px solid #00254a;
		color: #00254a;
	}

	&:hover .css-1v887om-boxCssProps-style-partImageStyle {
		box-shadow: 0 0 0.375rem 0 #0b75e1;
	}

	&:hover h4 {
		color: #0b75e1;
	}
`

const headingLinkStyle = css`
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
`

const partImageStyle = css`
	width: 100%;
	padding: 1.25rem;
	border-radius: 0.25rem;
	box-shadow: 0 0 0.375rem 0 #d1d5db;
	background-color: #fff;
	display: block;
	height: 186px;
	width: 186px;
	min-height: 186px;
	min-width: 186px;
	max-height: 186px;
	max-width: 186px;
`

const productTitle = css`
	font-size: 1.25rem;
	font-weight: 500;
	line-height: normal;
	overflow-wrap: anywhere;
	text-overflow: ellipsis;
	display: block;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	line-height: 1.1;
	max-height: 4.5em;
	overflow: hidden;
`
