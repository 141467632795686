import { FC } from "react"

import { css } from "@emotion/react"
import { Box, Button, ButtonProps, Divider, Heading, HeadingProps } from "@ncs/web-legos"
import { PartPreviewCardEcomm } from "."

interface PartPreviewRowProps {
	heading?: string
	partIds: (string | null | undefined)[]
	button?: ButtonProps
	headingVariant?: HeadingProps["variant"]
}

export const PartPreviewRowEcomm: FC<PartPreviewRowProps> = ({
	heading,
	partIds,
	button,
	headingVariant = "h2",
}) => {
	const partIdsToShow: string[] = [
		...new Set(partIds?.filter((id) => id).map((id) => String(id))),
	]

	if (partIdsToShow.length === 0) {
		return null
	}

	return (
		<>
			{heading && (
				<Heading variant={headingVariant} bold mt={1} mb={1.5}>
					{heading}
				</Heading>
			)}

			<Box
				d="flex"
				css={css`
					padding-bottom: 1em;
					max-width: 100vw;
					overflow-x: auto;
					&::-webkit-scrollbar {
						height: 4px;
					}

					&::-webkit-scrollbar-thumb {
						background-color: rgba(0, 0, 0, 0.2);
						border-radius: 2px;
					}

					&::-webkit-scrollbar-track {
						background: transparent;
					}
				`}
			>
				{partIdsToShow?.map((id: string) => (
					<Box
						key={id}
						css={css`
							margin-right: 24px;
						`}
					>
						<PartPreviewCardEcomm partId={id} css={previewCardStyle} />
					</Box>
				))}
			</Box>

			{button && (
				<Box mt={2}>
					<Button {...button} />
				</Box>
			)}

			<Divider mt={3} mb={5} />
		</>
	)
}

const previewCardStyle = css`
	// Have this fill to the max height of the container.
	height: 100%;
`
