import { FC, memo, useState } from "react"
import { css } from "@emotion/react"
import { Link } from "react-router-dom"
import { useAuth } from "@ncs/ncs-api"
import { Box } from "@ncs/web-legos"
import { ContentWrapperEcomm } from "./ContentWrapperEcomm"
import { Logo } from "./Logo"
import { MobileNavModal } from "./MobileNavModal"
import { ShopBarEcomm } from "./shop-bar"

export const SiteHeaderEcomm: FC = memo(() => {
	const [showNavModal, setShowNavModal] = useState(false)
	const { user } = useAuth()
	return (
		<>
			{user != null ?
				<>
					<header>
						<ShopBarEcomm />
					</header>
					<MobileNavModal isOpen={showNavModal} onClose={() => setShowNavModal(false)} />
				</>
			:	<header css={headerStyle(user != null)}>
					<ContentWrapperEcomm>
						<Box
							pb={1}
							display="flex"
							justifyContent={user ? "space-between" : "start"}
							pl={4}
						>
							<Link to="/">
								<Logo width="11rem" company={user ? "ncs" : "ncsWhite"} />
							</Link>
						</Box>
					</ContentWrapperEcomm>
					{user && <ShopBarEcomm />}
				</header>
			}
		</>
	)
})

const headerStyle = (user: boolean) => css`
	padding-top: 1rem;
	background-color: ${user ? "#ffffff" : "#00254a"};
`
